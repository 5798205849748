<div class="form-field-container" class="{{class}}" [ngClass]="{'form-field-row': layout === 'row'}">
    <div class="form-field-header" *ngIf="showHeader">
        <div class="form-field-title-container">
            <div *ngIf="titleIcon" class="title-icon {{titleIcon}}"></div>
            <span class="form-field-title">{{title}}</span>
            <div
                *ngIf="helpText"
                class="form-field-info infoicon"
                matTooltip="{{helpText}}"
                matTooltipPosition="above"
            ></div>
        </div>
        <span class="form-field-label" *ngIf="label">{{label}}</span>
        <span class="form-field-count" *ngIf="count || count === 0">{{count}}</span>
        <div class="form-field-title-container title-container-2" *ngIf="title2">
            <span class="form-field-title title2">{{title2}}</span>
            <div
                    *ngIf="helpText2"
                    class="form-field-info infoicon"
                    matTooltip="{{helpText2}}"
                    matTooltipPosition="below"
            ></div>
        </div>
        <div class="form-header-toggle-container" *ngIf="showToggle">
            <span
                    class="toggle-option-text"
                    [ngClass]="{'toggle-option-selected': !headerToggle}"
                    (click)="toggleHeader('left')"
            >
                OFF
            </span>
            <div class="form-header-toggle" (click)="toggleHeader()">
                <div
                        class="form-toggle-switch form-toggle-switch-inverse"
                        [ngClass]="{'toggle-left': !headerToggle, 'toggle-right': headerToggle}"
                >
                    <div class="form-toggle-indicator"></div>
                    <div class="form-toggle-indicator"></div>
                </div>
            </div>
            <span
                    class="toggle-option-text"
                    [ngClass]="{'toggle-option-selected': headerToggle}"
                    (click)="toggleHeader('right')"
            >
                ON
            </span>
        </div>
    </div>
    <div class="form-field-content" [style]="contentStyle">
        <ng-content></ng-content>
    </div>
    <lib-multi-action-button
        *ngIf="headerActions"
        [actions]="headerActions"
        (actionRequested)="actionClicked($event)"
    ></lib-multi-action-button>
</div>
