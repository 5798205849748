<div class="loginForm standard-form-fields" (keyup.enter)="next()">
    <div *ngIf="!isLoading">
        <div class="title">Ziti Admin Console</div>
        <div class="subtitle">Welcome, please login to continue</div>
        <lib-selector-input
            *ngIf="!svc.originIsController && !showNoControllers"
            [fieldName]="'Edge Controller'"
            [(fieldValue)]="selectedEdgeController"
            [error]="edgeNameError"
            [placeholder]="'Select an Edge Controller'"
            [valueList]="edgeControllerList"
            [helpText]="helpText"
            [fieldClass]="controllerInvalid ? 'invalid' : ''"
            (fieldValueChange)="edgeChanged($event)"
        >
        </lib-selector-input>
        <div *ngIf="showNoControllers"class="no-controllers-warning">
            <span class="warning-title"><b>No Controllers Available</b></span>
            <span>You must set at least one controller URL, prior to starting the ZAC node server</span>
            <span>Set an environment variable named <b class="env-var-text">ZAC_CONTROLLER_URLS</b> before starting the server</span>
            <span>It must be a comma seperated list of 1 or more URLs. For Example:</span>
            <code>ZAC_CONTROLLER_URLS=https://localhost:1280,https://example.domain.io:443</code>
        </div>
        <label for="Username" class="userlogin open" [ngClass]="{'login-disabled': showNoControllers}">Username</label>
        <input
            id="Username"
            name="username"
            type="text"
            autocomplete="on"
            required
            [ngClass]="{'login-disabled': showNoControllers}"
            [placeholder]="'enter username of controller'"
            (change)="usernameChange($event)"
            (keyup)="usernameChange($event)"
        >
        <label for="Password" class="userlogin open" [ngClass]="{'login-disabled': showNoControllers}">Password</label>
        <input
            id="Password"
            name="password"
            type="password"
            autocomplete="on"
            required
            [ngClass]="{'login-disabled': showNoControllers}"
            [placeholder]="'enter password of controller'"
            (change)="passwordChange($event)"
            (keyup)="passwordChange($event)"
        >
        <div class="buttons">
            <button class="button" (click)="login()" [ngClass]="{'login-disabled': showNoControllers}">Login</button>
        </div>
    </div>
</div>
<lib-loading-indicator *ngIf="isLoading" [isLoading]="isLoading"></lib-loading-indicator>
